import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Nav from '../components/NavSub'
import Layout from '../components/layout'
import Header from '../components/Header'
import coverPhoto from '../assets/images/banner-11.jpg'

class ServicesForensic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: true
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
          <title>Sexual Abuse Investigations - Carien Zeeman</title>
          <meta name="description" content="Social Worker in Private Practice" />
          <meta name="keywords" content="carien zeeman,forensic social worker,care and contact evaluations,child assessments,forensic investigations,mediation,supervised visitation,therapy,training" />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="info" className="main">
            <div className="content sub-section">
              <header className="major">
                <h2>Sexual Abuse Investigations</h2>
              </header>
              <span className="image main">
                <img src={coverPhoto} alt="" />
              </span>
              <p>The forensic investigation process entails the investigation of allegations or suspicions of sexual abuse. Carien follows a structured investigation process that is characterised by a holistic and objective approach. By utilizing scientific techniques, following a process with a literature and research foundation and considering multiple hypotheses, a sensitive and legally sound process is implemented.</p>
              <p>The forensic investigation process has three divisions:</p>
              <li>The investigations,</li>
              <li>The compiling of the report,</li>
              <li>Appearing in court to testify as an expert witness on the report (if applicable).</li>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ServicesForensic
