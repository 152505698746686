import React from 'react'
import Scrollspy from 'react-scrollspy'
import { Link } from 'gatsby'

const NavSub = (props) => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy items={['home', 'about', 'services', 'contact']} currentClassName="is-active" offset={-300}>
      <li><Link to="/#home">Home</Link></li>
      <li><Link to="/#about">About</Link></li>
      <li><Link to="/#services">Services</Link></li>
      <li><Link to="/#contact">Contact</Link></li>
    </Scrollspy>
  </nav>
)

export default NavSub
